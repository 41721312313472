import { styled } from '@mui/material'

export const Wrapper = styled('div')(({ theme }) => ({
  height: '100%',
  '.rbc-event': {
    backgroundColor: theme.palette.primary.main
  },
  '.rbc-event.rbc-selected': {
    backgroundColor: theme.palette.primary.main
  }
}))
