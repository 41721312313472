import { Controller, FieldValues, Path } from 'react-hook-form'

import {
  Autocomplete,
  Icon,
  Icons,
  Select,
  Switch,
  Textfield
} from '@clientbase/clientbase-library'
import {
  Box,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'
import { AutoCompleteBillingsParams } from 'models/Billings/AutoComplete'
import { expirationOptions } from 'templates/Charge/Modals/EditRecurrence/templates/ExpirationOptions.utils'

import { DatePicker } from 'components/DatePicker'

import { currency } from 'utils'

import { InfosProps } from './Infos.interface'

const Infos = <T extends FieldValues>({
  control,
  isCharge,
  isEnablePlan,
  plan,
  plans,
  nameCustomer,
  customers,
  setCustomerUuid,
  edit,
  setRecieverType,
  recieverType,
  setGroupUuid,
  groups,
  limit_days_for_payment
}: InfosProps<T>) => {
  return (
    <>
      {edit && nameCustomer && (
        <Textfield placeholder="Buscar cliente" value={nameCustomer} disabled />
      )}

      {!edit && (
        <>
          {!isCharge && (
            <Box mb={isEnablePlan ? '32px' : '4px'} width="100%">
              <Controller
                control={control}
                name={'isEnablePlan' as Path<T>}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Box display="flex" alignItems="center" gap="8px">
                      <Typography variant="smBold">
                        Vincular um produto na recorrência
                      </Typography>
                      <Switch
                        checked={value}
                        onChange={onChange}
                        name="isEnablePlan"
                      />
                    </Box>
                  )
                }}
              />
              {isEnablePlan && plans && (
                <Controller
                  control={control}
                  name={'plan' as Path<T>}
                  render={({
                    field: { onChange, value },
                    fieldState: { error }
                  }) => {
                    return (
                      <Select
                        name="plan"
                        helperText={error?.message}
                        error={!!error?.message}
                        fullWidth={true}
                        onChange={onChange}
                        sx={{ height: '42px' }}
                        value={value}
                        disabled={!!plan}
                        renderValue={(selected) => {
                          if (plan) {
                            return plan.name
                          }
                          if ((selected as string) === 'initial') {
                            return (
                              <Typography sx={{ color: '#BCBCBC' }}>
                                Selecione um produto para vincular
                              </Typography>
                            )
                          }
                          const uuid = selected as string
                          const name = plans.filter(
                            (plan) => plan.uuid === uuid
                          )
                          if (name.length > 0) {
                            return name[0].name
                          }
                          return 'Loading...'
                        }}
                      >
                        <MenuItem value="initial" sx={{ color: '#BCBCBC' }}>
                          Selecione um produto para vincular
                        </MenuItem>
                        {plans.map(
                          (
                            { amount, name, dueDay, uuid, icon, totalCycles },
                            index
                          ) => (
                            <MenuItem
                              key={index}
                              value={uuid}
                              sx={{ width: '100%' }}
                            >
                              <Box
                                width="100%"
                                display="flex"
                                justifyContent="space-between"
                                p="12px"
                                alignItems="center"
                              >
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  gap="12px"
                                >
                                  <Icon icon={icon as Icons} />
                                  <Box>
                                    <Typography variant="smBold">
                                      {name}
                                    </Typography>
                                    <Box display="flex" gap="4px">
                                      <Typography
                                        variant="smLight"
                                        color="#828282"
                                      >
                                        Valor:{' '}
                                        <strong>R$ {currency(amount)}</strong>,
                                      </Typography>
                                      <Typography
                                        variant="smLight"
                                        color="#828282"
                                      >
                                        Número de parcelas:{' '}
                                        <strong>
                                          {totalCycles
                                            ? `${totalCycles}x`
                                            : 'infinito'}
                                        </strong>
                                        ,
                                      </Typography>
                                      {!!dueDay && (
                                        <Typography
                                          variant="smLight"
                                          color="#828282"
                                        >
                                          Dia do vencimento:{' '}
                                          <strong>{dueDay}</strong>
                                        </Typography>
                                      )}
                                    </Box>
                                  </Box>
                                </Box>
                                <Radio checked={uuid === value} />
                              </Box>
                            </MenuItem>
                          )
                        )}
                      </Select>
                    )
                  }}
                />
              )}
            </Box>
          )}

          <Controller
            name={'recieverType' as Path<T>}
            control={control}
            render={({ field }) => (
              <RadioGroup
                aria-labelledby="group-type"
                value={field.value}
                onChange={(event) => {
                  field.onChange(event)
                  !!setRecieverType &&
                    setRecieverType(
                      event.target.value as 'customer' | 'group' | undefined
                    )
                }}
                row
                sx={{ gap: 2 }}
              >
                <FormControlLabel
                  value="customer"
                  control={<Radio />}
                  label="Cliente"
                />
                <FormControlLabel
                  value="group"
                  control={<Radio />}
                  label="Grupo"
                />
              </RadioGroup>
            )}
          />

          {recieverType === 'customer' ? (
            customers && nameCustomer ? (
              <Controller
                control={control}
                name={'customerUuid' as Path<T>}
                render={({ fieldState: { error } }) => {
                  let names = ''
                  if (nameCustomer.includes(';')) {
                    const ArrayUuid = nameCustomer.split(';')
                    ArrayUuid.forEach((uuid: string, index: number) => {
                      const customer = customers.find(
                        (customer) => customer.value === uuid
                      )
                      names = `${names}${customer?.label}${
                        ArrayUuid.length !== index + 1 ? ', ' : ''
                      }`
                    })
                  } else {
                    const customer = customers.find(
                      (customer) => customer.value === nameCustomer
                    )
                    names = customer?.label ? customer.label : nameCustomer
                  }

                  return (
                    <Textfield
                      name="customerUuid"
                      helperText={error?.message}
                      error={!!error?.message}
                      placeholder="Buscar cliente"
                      value={customers.length !== 0 ? names : 'Carregando...'}
                      disabled={!!names}
                    />
                  )
                }}
              />
            ) : (
              <Controller
                control={control}
                name={'customer' as Path<T>}
                render={({
                  field: { onChange, value },
                  fieldState: { error }
                }) => {
                  const errorTyped = error as any
                  return (
                    <Autocomplete
                      value={value}
                      onChange={(_, value) => {
                        const option = value as AutoCompleteBillingsParams
                        setCustomerUuid && setCustomerUuid(option.value)
                        onChange(option ?? { value: '', label: '' })
                      }}
                      renderOption={(props, option) => {
                        const optionTyped = option as AutoCompleteBillingsParams
                        return (
                          <Box
                            component="li"
                            {...props}
                            key={optionTyped.value}
                          >
                            {optionTyped.label}
                          </Box>
                        )
                      }}
                      options={customers || []}
                      renderInput={(params) => (
                        <Textfield
                          helperText={errorTyped?.label?.message as any}
                          error={!!errorTyped?.label?.message as any}
                          placeholder="Buscar cliente"
                          name="customer"
                          {...params}
                        />
                      )}
                    />
                  )
                }}
              />
            )
          ) : null}

          {recieverType === 'group' && (
            <Controller
              control={control}
              name={'group' as Path<T>}
              render={({
                field: { onChange, value },
                fieldState: { error }
              }) => {
                const errorTyped = error as any
                return (
                  <Autocomplete
                    value={value}
                    onChange={(_, value) => {
                      const option = value as AutoCompleteBillingsParams
                      setGroupUuid && setGroupUuid(option.value)
                      onChange(option ?? { value: '', label: '' })
                    }}
                    renderOption={(props, option) => {
                      const optionTyped = option as AutoCompleteBillingsParams
                      return (
                        <Box component="li" {...props} key={optionTyped.value}>
                          {optionTyped.label}
                        </Box>
                      )
                    }}
                    options={groups || []}
                    renderInput={(params) => (
                      <Textfield
                        helperText={errorTyped?.label?.message as any}
                        error={!!errorTyped?.label?.message as any}
                        placeholder="Buscar grupo"
                        name="group"
                        {...params}
                      />
                    )}
                  />
                )
              }}
            />
          )}
        </>
      )}
      <Grid container={true} mt="0px" spacing={2}>
        <Grid item={true} xs={4} alignSelf={'end'}>
          <Controller
            control={control}
            name={'amountBilled' as Path<T>}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Textfield
                customStartAdornment={
                  <Typography mr="6px" variant="mdLight">
                    R$
                  </Typography>
                }
                helperText={error?.message}
                error={!!error?.message}
                label="Qual o valor a ser cobrado?"
                mask="money"
                name="amountBilled"
                onChange={onChange}
                startAdornment={true}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item={true} xs={4}>
          <Controller
            control={control}
            name={'dueDate' as Path<T>}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <DatePicker
                  helperText={error?.message}
                  error={!!error?.message}
                  label={
                    isCharge
                      ? 'Qual a data de vencimento?'
                      : 'Qual o vencimento da primeira fatura?'
                  }
                  minDate={new Date()}
                  onChange={onChange}
                  renderInput={(props: any) => (
                    <Textfield {...props} name="dueDate" />
                  )}
                  toolbarPlaceholder="Escolha a data de vencimento"
                  value={value ? value : null}
                />
              )
            }}
          />
        </Grid>
        <Grid item={true} xs={4}>
          <Controller
            control={control}
            name={'expirationField' as Path<T>}
            render={({ fieldState: { error }, field: { onChange, value } }) => (
              <Select
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 400
                    }
                  }
                }}
                label={`Expiração da ${
                  isCharge || edit ? 'cobrança' : 'recorrência'
                }`}
                helperText={error?.message}
                error={!!error?.message}
                fullWidth
                name="expirationField"
                defaultValue={limit_days_for_payment}
                value={parseInt(value) || 1}
                displayEmpty
                onChange={onChange}
              >
                {' '}
                {expirationOptions.map((item) => (
                  <MenuItem key={item.label} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Infos
