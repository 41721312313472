import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { Button, Dialog, Icon, Loader } from '@clientbase/clientbase-library'
import { Box, Tooltip, Typography } from '@mui/material'
import { DataService } from 'api/DataService'
import { useFetch } from 'hooks/use-fetch'
import { NFeParams } from 'models/NFe'
import moment from 'moment'

import { formatMoney } from 'utils'
import { toastProps } from 'utils/types/toast-props'

import { getHoverText } from './ModalInvoiceDetails.utils'
import * as S from './ModalInvoiceDetailsContent.styles'
import { getStyle } from './ModalInvoiceDetailsContent.styles'

interface ModalInvoiceDetailsContentProps {
  uuid: string
  handleReloadTable: () => void
  handleCloseModal: () => void
}

const ModalInvoiceDetailsContent = ({
  uuid,
  handleReloadTable,
  handleCloseModal
}: ModalInvoiceDetailsContentProps) => {
  const [loading, setLoading] = useState(false)
  const [invoice, setInvoice] = useState<NFeParams>({} as NFeParams)

  const [openDrawer, setOpenDrawer] = useState(false)
  const [confirmSend, setConfirmSend] = useState(false)
  const [isOpenDialogReissue, setIsOpenDialogReissue] = useState(false)
  const handleToggleDrawer = () => {
    setOpenDrawer((open) => !open)
  }

  const handleToogleConfirmSend = (): void => {
    setConfirmSend((prevState) => !prevState)
  }

  const [, result, , status] = useFetch({
    shouldRun: true,
    method: 'GET',
    url: `/v1/nfses/${uuid}`
  })

  const deleteNFS = async () => {
    const id = toast.loading('Cancelando nota fiscal...')
    const response = await DataService({
      type: 'DELETE',
      url: `/v1/nfses/${uuid}`
    })

    const errorMessage =
      response.error && (response.detail_error || response.message)

    toast.update(id, {
      render: response.error ? errorMessage : response.data.message,
      type: response.error ? 'error' : 'success',
      ...toastProps
    })

    if (response.status === 200) {
      handleReloadTable()
      handleCloseModal()
    }
    handleToggleDrawer()
  }

  const reissue = async () => {
    setLoading(true)
    const id = toast.loading('Reemitindo nota fiscal...')
    const response = await DataService({
      type: 'PATCH',
      url: `v1/nfses/${uuid}/reissue`
    })
    setLoading(false)
    setIsOpenDialogReissue(false)
    if (response.status === 200) {
      handleReloadTable()
      handleCloseModal()
    }

    toast.update(id, {
      render: response.error ? response.message : response.data.message,
      type: response.error ? 'error' : 'success',
      ...toastProps
    })
  }

  const sendNF = async () => {
    setLoading(true)
    const id = toast.loading('Enviando nota fiscal...')
    const response = await DataService({
      type: 'GET',
      url: `/v1/nfses/${uuid}/notify`
    })
    const errorMessage =
      response.error && (response.detail_error || response.message)

    toast.update(id, {
      render: response.error ? errorMessage : response.data.message,
      type: response.error ? 'error' : 'success',
      ...toastProps
    })

    setLoading(false)

    handleToogleConfirmSend()
  }

  useEffect(() => {
    setInvoice(result?.data as NFeParams)
  }, [result])

  if (status !== 'settled') {
    return <Loader />
  }
  return (
    <S.Wrapper>
      <S.WrapperHeader>
        <Box display="flex" alignItems="center" gap="18px">
          <S.ContainerIcon>
            <Icon icon="info" colorSX="#56BB4D" />
          </S.ContainerIcon>
          <Typography variant="mdBold">Detalhes nota fiscal</Typography>
        </Box>
        <Box>
          <Tooltip
            arrow
            title={getHoverText(invoice?.status).text || 'pending'}
          >
            <S.Status status={invoice?.status || 'pending'}>
              {getStyle(invoice?.status || 'pending')?.label}
            </S.Status>
          </Tooltip>
        </Box>
      </S.WrapperHeader>

      <S.WrapperContent>
        <Box display="flex" flexDirection="column" width="100%" gap="12px">
          {invoice?.nfse_number && (
            <Box display="flex" gap={1.25} alignItems="end">
              <Typography variant="mdLight" color="#777777">
                Número da nota
              </Typography>
              <S.Separator />
              <Typography variant="mdBold">{invoice?.nfse_number}</Typography>
            </Box>
          )}

          {invoice?.nfse_item?.created_at && (
            <Box display="flex" gap={1.25} alignItems="end">
              <Typography variant="mdLight" color="#777777">
                Data/Hora emissão
              </Typography>
              <S.Separator />
              <Typography variant="mdBold">
                {moment(invoice?.nfse_item?.created_at).format(
                  'DD/MM/YYYY HH:mm:ss'
                )}
              </Typography>
            </Box>
          )}
          {invoice?.customer?.name && (
            <Box display="flex" gap={1.25} alignItems="end">
              <Typography variant="mdLight" color="#777777">
                Cliente
              </Typography>
              <S.Separator sx={{ marginTop: '4px' }} />
              <Typography variant="mdBold" display="flex" alignItems="center">
                {invoice?.customer?.name}{' '}
                <Icon
                  icon="open"
                  width="21px"
                  height="21px"
                  ml="6px"
                  onClick={() =>
                    window.open(
                      `/clientes/${invoice?.customer?.uuid}`,
                      '_blank'
                    )
                  }
                  sx={{ cursor: 'pointer', color: '#777877' }}
                />
              </Typography>
            </Box>
          )}

          {invoice?.nfse_item?.amount && (
            <Box display="flex" gap={1.25} alignItems="end">
              <Typography variant="mdLight" color="#777777">
                Valor
              </Typography>
              <S.Separator />
              <Typography variant="mdBold">
                {formatMoney(invoice?.nfse_item.amount)}
              </Typography>
            </Box>
          )}

          {invoice?.nfse_item?.nfse_issuer_service && (
            <Box display="flex" flexDirection="column">
              <Typography variant="mdLight" color="#777777" mb="6px">
                Item lista de serviço
              </Typography>
              <Typography variant="mdBold">
                {invoice?.nfse_item?.nfse_issuer_service.code} -{' '}
                {invoice?.nfse_item?.nfse_issuer_service.description}
              </Typography>
            </Box>
          )}

          {invoice?.nfse_item?.description && (
            <Box display="flex" flexDirection="column">
              <Typography variant="mdLight" color="#777777" mb="6px">
                Descrição
              </Typography>
              <Typography variant="mdBold">
                {invoice?.nfse_item?.description}
              </Typography>
            </Box>
          )}

          {invoice?.status_detail && (
            <Box display="flex" flexDirection="column">
              <Typography variant="mdLight" color="#BE3C49" mb="6px">
                Erro
              </Typography>
              <Typography variant="mdBold" color="#BE3C49">
                {invoice?.status_detail}
              </Typography>
            </Box>
          )}
        </Box>
      </S.WrapperContent>

      <S.WrapperFooter flexDirection={{ xs: 'column', sm: 'row' }}>
        {invoice?.url_pdf && (
          <Button variantButton="outlined" variantTypography="mdBold">
            <a
              href={invoice?.url_pdf}
              download
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <Typography variant="mdBold" color="#000">
                BAIXAR PDF
              </Typography>
            </a>
          </Button>
        )}

        {invoice?.url_xml && (
          <Button variantButton="outlined">
            <a
              href={invoice?.url_xml}
              download
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <Typography variant="mdBold" color="#000">
                BAIXAR XML
              </Typography>
            </a>
          </Button>
        )}

        {invoice?.status === 'confirmed' && (
          <Button
            sx={{
              background: '#757675',
              [':hover']: { background: '#757675' }
            }}
            onClick={handleToggleDrawer}
          >
            CANCELAR NF
          </Button>
        )}
        {invoice?.status === 'failed' && (
          <Button
            sx={{
              background: '#757675',
              [':hover']: { background: '#757675' }
            }}
            onClick={() => setIsOpenDialogReissue(true)}
          >
            REEMITIR NF
          </Button>
        )}

        {/* TODO: A implementar ainda */}
        {invoice?.status === 'confirmed' && (
          <Button
            variantButton="green"
            onClick={handleToogleConfirmSend}
            disabled={loading}
            loading={loading}
          >
            ENVIAR NOTA PARA O CLIENTE
          </Button>
        )}
      </S.WrapperFooter>

      <Dialog
        icon="info"
        title="CANCELAR NOTA FISCAL"
        description="Confirma o cancelamento desta nota fiscal?"
        setIsOpenDialog={handleToggleDrawer}
        isOpenDialog={openDrawer}
      >
        <Box display="flex" alignItems="center" gap={1} width="100%">
          <Button
            variantButton="contained"
            onClick={handleToggleDrawer}
            fullWidth
          >
            Não
          </Button>
          <Button variantButton="contained" onClick={deleteNFS} fullWidth>
            Sim
          </Button>
        </Box>
      </Dialog>

      <Dialog
        icon="info"
        title="REEMITIR NOTA FISCAL"
        description="Confirma a reemissão desta nota fiscal?"
        setIsOpenDialog={setIsOpenDialogReissue}
        isOpenDialog={isOpenDialogReissue}
      >
        <Box display="flex" alignItems="center" gap={1} width="100%">
          <Button
            variantButton="contained"
            onClick={() => setIsOpenDialogReissue(false)}
            fullWidth
          >
            Não
          </Button>
          <Button variantButton="contained" onClick={reissue} fullWidth>
            Sim
          </Button>
        </Box>
      </Dialog>

      <Dialog
        icon="info"
        title="ENVIAR NOTA FISCAL"
        description="Confirma o envio dessa nota fiscal por e-mail?"
        setIsOpenDialog={handleToogleConfirmSend}
        isOpenDialog={confirmSend}
      >
        <Box display="flex" alignItems="center" gap={1} width="100%">
          <Button
            variantButton="contained"
            onClick={handleToogleConfirmSend}
            fullWidth
          >
            Não
          </Button>
          <Button variantButton="contained" onClick={sendNF} fullWidth>
            Sim
          </Button>
        </Box>
      </Dialog>
    </S.Wrapper>
  )
}

export default ModalInvoiceDetailsContent
