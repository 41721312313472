import React, { useState } from 'react'

import { Icon, Icons } from '@clientbase/clientbase-library'
import { Typography } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'

import * as S from './'

type RoundedItemProps = {
  tooltipTitle?: string
  name?: string
  icon?: Icons | JSX.Element
  color?: string
  size?: 'xxs' | 'xs' | 'small' | 'normal'
  disableHover?: boolean
  isNew?: boolean
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const RoundedItem = ({
  tooltipTitle,
  name,
  icon,
  color,
  size = 'small',
  isNew = false,
  disableHover,
  ...props
}: RoundedItemProps) => {
  const [isHovered, setHovered] = useState(false)

  const handleMouseOver = () => {
    setHovered(true)
  }

  const handleMouseOut = () => {
    setHovered(false)
  }

  return (
    <Tooltip
      enterTouchDelay={0}
      title={tooltipTitle}
      sx={{ cursor: disableHover ? 'auto' : 'pointer' }}
    >
      <S.Wrapper
        {...props}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        {isNew && <S.NewBanner>Novidade</S.NewBanner>}

        <S.IconWrapper
          size={size}
          color={color}
          isHovered={disableHover ? false : isHovered}
        >
          {typeof icon === 'object' ? (
            React.cloneElement(icon, {
              color: isHovered && !disableHover ? '#FFF' : color || '#A3A6A2',
              width: 18
            })
          ) : (
            <Icon
              icon={icon as Icons}
              style={{
                color: isHovered && !disableHover ? '#FFF' : color || '#A3A6A2'
              }}
              fontSize="small"
            />
          )}
        </S.IconWrapper>
        {name && (
          <Typography variant="smLight" fontSize={10} mt="4px" width="70px">
            {name}
          </Typography>
        )}
      </S.Wrapper>
    </Tooltip>
  )
}

export default RoundedItem
