import { Button } from '@clientbase/clientbase-library'
import { styled } from '@mui/material'

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '16px',
  marginTop: '4px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  }
}))

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center'
}))

export const MonthLabel = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  padding: '4px 20px',
  width: '280px',
  textAlign: 'center',
  borderRadius: '4px',
  minHeight: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#f5f7f5',
  gap: '12px',
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
}))

export const ButtonAntecipate = styled(Button)(({ theme }) => ({
  position: 'absolute',
  right: 32,
  top: 42,
  height: '40px',
  '&:hover': {
    height: '40px'
  },
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    right: 0,
    top: 0,
    marginTop: '12px'
  }
}))
