import { Typography } from '@mui/material'
import styled from '@mui/system/styled'

export const Wrapper = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8
}))

export const Header = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxSizing: 'border-box'
}))

export const Heading = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  color: theme.palette.neutral[200],
  lineHeight: 1
}))

type ColorPallete = {
  color: string
}

export const ColorPallete = styled('div')<ColorPallete>(({ color }) => ({
  width: 24,
  height: 20,
  borderRadius: 4,
  marginRight: 8,
  background: color
}))

export const GroupWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  width: '100%'
}))

export const InputColor = styled('input')(() => ({
  display: 'none',
  opacity: 0,
  width: 0
}))

export const Label = styled('label')(({ theme }) => ({
  width: '100%',

  '&:has(input:disabled)': {
    color: theme.palette.neutral[400],
    cursor: 'not-allowed'
  }
}))

export const InputColorWrapper = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  border: '1px solid hsla(108, 2%, 64%, 1)',
  padding: '10px 8px',
  borderRadius: 8,
  gap: 8,

  '& input[type="color"]': {
    position: 'absolute'
  }
}))

export const LabelText = styled('p')(() => ({
  font: '400 15px/140% Inter, "sans-serif"',
  color: '#3F403F',
  paddingBottom: 4,
  margin: 0
}))
