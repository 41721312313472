import React, { useEffect } from 'react'

import { Icon } from '@clientbase/clientbase-library'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Collapse, List, Box } from '@mui/material'
import { useRouter } from 'next/router'
import { ParsedUrlQueryInput } from 'querystring'

import { SubheaderProps } from 'components/Drawer/Drawer'

import { MenuParams } from './Menu.interface'
import * as S from './Menu.styles'

export const Menu: React.FC<MenuParams> = ({
  icon = 'add',
  label,
  router,
  handleClick,
  isExpanded,
  subheader,
  openMenu,
  handleOpenMenu,
  option,
  expandDrawer
}) => {
  const { push, pathname, query: queryRouter } = useRouter()

  const onMenuClick = (query?: ParsedUrlQueryInput) => {
    push({ query, pathname: router })
    handleClick()
  }

  const onMenuClickRouter = (subRouter?: string) => {
    push({ pathname: `${router}${subRouter}` })
    handleClick()
  }

  const checkUrlMatch = (router: string, subRouter?: string) => {
    if (subRouter) {
      return pathname.split('/').includes(subRouter.split('/')[1])
    }
    if (pathname.split('/').length > 2) {
      return pathname.split('/').includes(router.substring(1))
    }
    return pathname === router
  }

  const activeSubMenu = (
    router: string,
    tab?: number | string,
    subRouter?: string
  ) => {
    if (tab || tab === 0) {
      return (
        (tab === Number(queryRouter.tab) || tab === queryRouter.tab) &&
        checkUrlMatch(router)
      )
    } else {
      return (
        pathname.split('/').includes(subRouter?.split('/')[1] as string) &&
        checkUrlMatch(router, subRouter)
      )
    }
  }

  const handleOpenMenuAndClick = () => {
    if (subheader && option) {
      handleOpenMenu(option)
      !!expandDrawer && expandDrawer()
    } else {
      onMenuClick()
    }
  }

  const handleClickSubHeader = (item: SubheaderProps) => {
    if (item?.query) {
      onMenuClick(item.query)
    } else {
      onMenuClickRouter(item.subRouter)
    }
  }

  useEffect(() => {
    if (checkUrlMatch(router) && router !== '/visao-geral') {
      handleOpenMenu(router === '/cobrancas' ? 'receives' : 'clients')
    }
  }, [])

  const withHighlight = subheader?.filter((item) => item.highlight)

  return (
    <Box>
      <S.ListItemButton
        isExpanded={isExpanded}
        active={checkUrlMatch(router)}
        onClick={handleOpenMenuAndClick}
      >
        <S.ListItemIcon>
          <Icon icon={icon} />
        </S.ListItemIcon>
        {isExpanded && (
          <>
            <S.ListItemText primary={label} active={checkUrlMatch(router)} />
            {subheader ? (
              openMenu[option as string] ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )
            ) : null}
          </>
        )}
      </S.ListItemButton>
      {subheader && isExpanded && (
        <Collapse
          in={
            withHighlight && withHighlight.length > 0
              ? true
              : option
              ? openMenu[option]
              : false
          }
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            {subheader.map((item, index) => (
              <S.ListItemSubHeader
                highlight={item?.highlight}
                activeSubmenu={activeSubMenu(
                  router,
                  item?.tab,
                  item?.subRouter
                )}
                key={index}
                onClick={() => handleClickSubHeader(item)}
              >
                <S.ListItemText
                  primary={item.label}
                  active={activeSubMenu(router, item?.tab, item?.subRouter)}
                  highlight={item.highlight}
                />
              </S.ListItemSubHeader>
            ))}
          </List>
        </Collapse>
      )}
    </Box>
  )
}
