import React from 'react'

import { styled, Tabs as TabsMui, TabsProps } from '@mui/material'

import Tab from 'components/Tab/Tab'

import { ObjectLabelValue } from 'utils/types/object-label-value'

interface TabsExtendsProps extends TabsProps {
  value: number | string
  tabs: Array<string> | Array<ObjectLabelValue>
  onChange: (event: React.SyntheticEvent, newValue: number) => void
  fontSize?: number
}

const Tabs = styled(({ tabs, fontSize = 15, ...props }: TabsExtendsProps) => (
  <TabsMui
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    variant="scrollable"
    scrollButtons="auto"
    allowScrollButtonsMobile
  >
    {tabs.map((tab, i) => (
      <Tab
        fontSize={fontSize}
        key={i}
        label={typeof tab === 'string' ? tab : tab.label}
        value={typeof tab === 'string' ? i : tab.value}
      />
    ))}
  </TabsMui>
))(({ theme }) => ({
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.main,
    bottom: '8px',
    display: 'flex',
    justifyContent: 'center'
  },
  '& .MuiTabs-scrollButtons.Mui-disabled ': {
    opacity: '0.3'
  },
  '& .MuiTabs-scrollButtons': {
    width: '30px'
  }
}))

export default Tabs
