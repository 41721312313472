/* eslint-disable sort-keys */

import { Icon } from '@clientbase/clientbase-library'
import { Box, Tooltip } from '@mui/material'
import { Payment } from 'models/Billings'
import { ConstructorReceivableParams } from 'models/Receivables'
import moment from 'moment'

import { ButtonRounded } from 'components/button'
import {
  TableNameWrapper,
  TableNickname
} from 'components/DynamicTable/DynamicTable.styles'

import { formatMoney } from 'utils/FormatMoney'

import { theme } from 'styles/theme'

type ConvertToRowsParams = {
  records: ConstructorReceivableParams[] | null
  handleOpenDetailInvoice: (uuid: string) => void
}

const formatDate = (date: string) => {
  if (date)
    return moment(date.slice(0, 10), 'YYYY-MM-DD')
      .format('DD/MM/YYYY')
      .toString()
  return
}

type CreateDataTable = {
  name: string
  dueDate: string
  paidDate: string
  amountPaid: string
  amount: string
  payments: Payment[]
  uuid: string
  handleOpenDetailInvoice: (uuid: string) => void
  nickname?: string
  installment?: string
}

export const createDataTable = ({
  name,
  dueDate,
  paidDate,
  amountPaid,
  amount,
  payments,
  uuid,
  handleOpenDetailInvoice,
  nickname,
  installment
}: CreateDataTable) => {
  const paymentTransform = {
    pix: 'Pix',
    boleto: 'Boleto',
    boleto_pix: 'Pix',
    credit_card: 'Cartão de crédito',
    credit_card_installments: 'Cartão de crédito',
    credit_card_recurring: 'Cartão de crédito'
  }

  const payment: Payment =
    payments.find((item) => item.status === 'paid') || ({} as Payment)
  const fee = Number(amountPaid) - Number(amount) ?? 0

  let payment_type = paymentTransform[payment.payment_type]
  payment_type =
    payment.payment_type.includes('credit_card') && Number(installment ?? 0) > 1
      ? payment_type + ' - Parcela ' + installment
      : payment_type

  return {
    uuid,
    name: (
      <TableNameWrapper>
        <p>{name}</p>
        <TableNickname>{nickname}</TableNickname>
      </TableNameWrapper>
    ),
    dueDate: formatDate(dueDate),
    paidDate: formatDate(paidDate),
    amountPaid: formatMoney(amountPaid),
    fee: formatMoney(fee),
    amountLiquid: formatMoney(amount),
    paymentType: payment_type,
    actions: (
      <Tooltip
        enterTouchDelay={0}
        arrow={true}
        placement="top"
        title="Detalhes cobrança"
      >
        <Box>
          <ButtonRounded
            onClick={() => handleOpenDetailInvoice(uuid)}
            variant="transparent"
          >
            <Icon icon="settings" sx={{ color: theme.palette.neutral[200] }} />
          </ButtonRounded>
        </Box>
      </Tooltip>
    )
  }
}

export const convertToRows = ({
  records,
  handleOpenDetailInvoice
}: ConvertToRowsParams) =>
  records?.map((record) => {
    if (!record) return {}
    return createDataTable({
      uuid: record?.billing.uuid,
      name: record.billing.customer.name,
      nickname: record.billing.customer?.nickname || '',
      dueDate: record.billing.due_date,
      paidDate: record.billing.date_paid,
      amountPaid: record.gross_amount,
      amount: record.amount,
      payments: record.billing.payments,
      handleOpenDetailInvoice,
      installment: record.installment
    })
  })
